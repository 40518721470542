import Header from "./components/Header";
import RecommendedVideos from "./components/RecommendedVideos";
import Sidebar from "./components/Sidebar";
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SearchPage from "./components/SearchPage";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/search/:searchTerm" element={
            <>
              <div className="app__page">
                <Sidebar />
                <SearchPage />
              </div>
            </>
          }/>
        
          <Route path="/" element={
             <>
               <div className="app__page">
                 <Sidebar />
                 <RecommendedVideos />
               </div>
             </>

          } />

        </Routes>
      </BrowserRouter>



    </div>
  );
}

export default App;
