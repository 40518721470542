import React, { useState } from 'react'
import './Header.css'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import VideoCallIcon from '@material-ui/icons/VideoCall'
import AppsIcon from '@material-ui/icons/Apps'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Avatar from '@material-ui/core/Avatar'
import { Link } from 'react-router-dom'

const Header = () => {

  const [inputSearch, setInputSearch] = useState('')

  return (
    <div className='header'>
      <div className="header_left">
        <MenuIcon />
        <Link to='/'>
        </Link>
      <img className='header__logo' src='/Assets/images/logo.png' alt='' />
      </div>
      <div className="header__input">
        <input type='text' placeholder='Search' onChange={e => setInputSearch(e.target.value)} value={inputSearch} />
        <Link to={`/search/${inputSearch}`}>
                  <SearchIcon className='header__inputButton' />
     </Link>
      </div>
      <div className="header__icons">
      <VideoCallIcon  className='header__icon'/>
      <AppsIcon  className='header__icon'/>
      <NotificationsIcon className='header__icon'/>
      <Avatar alt='Jerry Babatunde' src='/Assets/images/Jerry.png' />
      </div>
    </div>
  )
}

export default Header