import { TuneOutlined } from '@material-ui/icons'
import React from 'react'
import ChannelRow from './ChannelRow'
import './SearchPage.css'
import VideoRow from './VideoRow'

const SearchPage = () => {
    return (
        <div className='searchPage'>
            <div className="searchPage__filter">
                <TuneOutlined />
                <h2>FILTER</h2>
            </div>
            <hr />

            <ChannelRow
                image="/Assets/images/channelImage.jpg"
                channel="SUPERJCYBS"
                verified
                subs="5k"
                noOfVideos={52}
                description="Creating the best of tutorials for our audience. Bringing Your Smiles" />
            <hr />
            <VideoRow
                title="How to close your Telegram account"
                views="1.6M Views"
                subs="54k"
                description="Here we take you through how to do this without errors"
                timestamp="39 minutes ago"
                channel="SUPERJCYBS"
                image="/Assets/images/video4.jpg"
            />
            <VideoRow
                title="How to close your Telegram account"
                views="1.6M Views"
                subs="54k"
                description="Here we take you through how to do this without errors"
                timestamp="39 minutes ago"
                channel="SUPERJCYBS"
                image="/Assets/images/video4.jpg"
            />
            <VideoRow
                title="How to close your Telegram account"
                views="1.6M Views"
                subs="54k"
                description="Here we take you through how to do this without errors"
                timestamp="39 minutes ago"
                channel="SUPERJCYBS"
                image="/Assets/images/video4.jpg"
            />
            <VideoRow
                title="How to close your Telegram account"
                views="1.6M Views"
                subs="54k"
                description="Here we take you through how to do this without errors"
                timestamp="39 minutes ago"
                channel="SUPERJCYBS"
                image="/Assets/images/video4.jpg"
            />
            <VideoRow
                title="How to close your Telegram account"
                views="1.6M Views"
                subs="54k"
                description="Here we take you through how to do this without errors"
                timestamp="39 minutes ago"
                channel="SUPERJCYBS"
                image="/Assets/images/video4.jpg"
            />
        </div>
    )
}

export default SearchPage