import React from 'react'
import './RecommendedVideos.css'
import VideoCard from './VideoCard'

const RecommendedVideos = () => {
  return (
    <div className='recommendedVideos'>
      <h2>Recommended</h2>
      <div className="recommendedVideos__videos">
        <VideoCard
          title="FREE CODING LESSONS - Day 1 (Introduction)"
          views="5M Views"
          timestamp="3 days ago"
          channel="Super J Cy-Business And Services"
          channelImage="Assets/images/channelImage.jpg"
          image="Assets/images/video2.png"
        />
        <VideoCard
          title="Quick Shortcuts to 6 microsoft products"
          views="3.5M Views"
          timestamp="5 days ago"
          channel="Super J Cy-Business And Services"
          channelImage="Assets/images/channelImage.jpg"
          image="Assets/images/video6.jpg"
        />
        <VideoCard
          title="How to close your Telegram account"
          views=".1.9M Views"
          timestamp="1 week ago"
          channel="Super J Cy-Business And Services"
          channelImage="Assets/images/channelImage.jpg"
          image="Assets/images/video4.jpg"
        />
        <VideoCard
          title="How to get a foriegn number for FREE in Ghana"
          views="55M Views"
          timestamp="3 weeks ago"
          channel="Super J Cy-Business And Services"
          channelImage="Assets/images/channelImage.jpg"
          image="Assets/images/video3.jpg"
        />
        <VideoCard
          title="Step 1 to getting a website for FREE"
          views="12M Views"
          timestamp="3 months ago"
          channel="Super J Cy-Business And Services"
          channelImage="Assets/images/channelImage.jpg"
          image="Assets/images/video1.jpg"
        />

      </div>
    </div>
  )
}

export default RecommendedVideos